(function($) {
  var PLUGINS = {
    init: function() {
      //algunos plugins interesa solo inicializarlos en mobile
      // if (anchoventana>=md && !isbot){
      //     animaciones WOW
      //     new WOW().init();        
      // }

      /* personaliza el check con un ON-OFF */
      // var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
      // elems.forEach(function(html) {
      //   var switchery = new Switchery(html,{
      //     color : '#f1426e'
      //   });
      // });

      /* personaliza un slider */
      // $("#slide").ionRangeSlider({
      //     type: "single",
      //     min: 0,
      //     max: 50,
      //     from: 1,
      //     step: 5,
      //     grid: true,
      //     grid_snap: true,
      //     values:["1","10","20","30","40","50"],
      //     grid_num: 5,
      //     postfix: "km"
      // });

      /* el combo a un formato con más opciones */
      $(".chosen-select:not(.chosen-select-especial)").chosen({disable_search_threshold: 10});

      /* personaliza el check */
      $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green',
      });

      /* personaliza un campo numerico con + y - */
      // $(".touchspin").TouchSpin({
      //     buttondown_class: 'btn btn-white',
      //     buttonup_class: 'btn btn-white',
      //     min:1,
      //     max:100
      // });

      /* carrusel swiper */
      // var swiper = new Swiper('.carrusel', {
      //     pagination: '.swiper-pagination',
      //     slidesPerView: 1,
      //     paginationClickable: true,
      // });


      // módulo galeria 
      /*
      window.swiperModalGaleria = new Swiper('.swiper-container-modalgaleria', {
          observer: true,
          observeParents: true,        
          navigation: {
            nextEl: '.swiper-button-next-modalgaleria',
            prevEl: '.swiper-button-prev-modalgaleria',
          },
          slidesPerView: 1,
          paginationClickable: true,
      });
      */
      window.swiperModalGaleria=Array(); 
      $(".swiper-container-modalgaleria").each(function(index, el) {

        //idgal=$(this).parent().parent().attr("data-idgal");
        idgal=$(this).closest(".modal-galeria-v2").attr("data-idgal");

        console.log("Añado modalgaleria: "+idgal);

        nombreObjeto=".swiper-container-modalgaleria-"+idgal;
        
        window.swiperModalGaleria[parseInt(idgal,10)] = new Swiper(nombreObjeto, {
            observer: true,
            observeParents: true,        
            navigation: {
              nextEl: nombreObjeto+' .swiper-button-next-modalgaleria',
              prevEl: nombreObjeto+' .swiper-button-prev-modalgaleria',
            },
            slidesPerView: 1,
            paginationClickable: true,
        }); 

      });     

      window.swiperModalGaleriaThumb=Array();
      $(".swiper-container-modalgaleria-thumb").each(function(index, el) {

        //idgal=$(this).parent().parent().attr("data-idgal");
        idgal=$(this).closest(".modal-galeria-v2").attr("data-idgal");        

        console.log("Añado modalgaleria thumb: "+idgal);

        nombreObjeto=".swiper-container-modalgaleria-thumb-"+idgal;

        console.log ("al objeto: "+nombreObjeto);


        window.swiperModalGaleriaThumb[parseInt(idgal,10)] =  new Swiper(nombreObjeto, {
            observer: true,
            observeParents: true,        
            navigation: {
              nextEl: nombreObjeto+' .swiper-button-next-modalgaleria-thumb',
              prevEl: nombreObjeto+' .swiper-button-prev-modalgaleria-thumb',
            },
            slidesPerView: 8,
            paginationClickable: true,
        }); 



      });

      var mySwipermenuhorizontal = new Swiper('.swiper-menuhorizontal', {
        navigation: {
          nextEl: '.swiper-menuhorizontal-button-next',
          prevEl: '.swiper-menuhorizontal-button-prev',
        },
        // centeredSlides: true,
        // centeredSlidesBounds: true,
        slidesPerView: 5,
        breakpoints: {
          // when window width is <= 320px
          320: {
            slidesPerView: 1,
          },
          // when window width is <= 480px
          480: {
            slidesPerView: 2,
          },
          // when window width is <= 640px
          960: {
            slidesPerView: 3,
          },
          // when window width is <= 640px
          1200: {
            slidesPerView: 4,
          }          
        }        
      });


      $(".chosen-select-maxselect").chosen({disable_search_threshold: 10,max_selected_options: 5});
      $(".chosen-select-maxselect").bind("chosen:maxselected", function () { jQuery('#modalAlerta').mostrar_msg("Lo sentimos", "Máximo 5 etiquetas") });



      var mySwiperInfoFases = new Swiper('#swiperInfoPases', {
        navigation: {
          nextEl: '.swiper-swiperInfoPases-button-next',
          prevEl: '.swiper-swiperInfoPases-button-prev',
        },
        slidesPerView: 3,
        observer: true,
        observeParents: true,
        initialSlide:1,
        centerInsufficientSlides: true,
        breakpoints: {
          // when window width is <= 320px
          320: {
            slidesPerView: 1,
          },
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
          },
          // when window width is <= 640px
          960: {
            slidesPerView: 2,
          },
          // when window width is <= 640px
          1200: {
            slidesPerView: 3,
          },
          // when window width is <= 640px
          10000: {
            slidesPerView: 3,
          }         
        }        
      });



      window.swiper1Col=Array(); 
      $(".swiper-container-1col").each(function(index, el) {
        
        window.swiper1Col.push(new Swiper($(this), {
            observer: true,
            observeParents: true,        
            slidesPerView: 1,
            autoplay: {
              delay: 6000,
            },
          navigation: {
            nextEl: $(this).find('.swiper-button-next'),
            prevEl: $(this).find('.swiper-button-prev'),
          },            
        })); 

      });       


    }

  };
 // Attach function to window
  window.PLUGINS = PLUGINS;
})(jQuery);

(function ($) {
    $.fn.mostrar_msg = function(_title, _msg){
        if ($(this).find('.modal-title').length && $(this).find('.modal-body').length){
            $(this).find('.modal-title').html(_title);
            $(this).find('.modal-body').html('<p>'+_msg+'</p>');
            $(this).modal();
        } else {
            alert(_title+": "+_msg);
        }
    };
 
}(jQuery));
