(function($) {
  var LISTENERS = {

    init: function() {

      
      $('[data-toggle="tooltip"]').tooltip({animation:false});
      $('[data-toggle="popover"]').popover({ trigger: "manual" , html: true, animation:true})
        .on("mouseenter", function () {
            var _this = this;
            $(this).popover("show");
            if (!$(_this).hasClass('js-tip-faq')){
              $(".popover").on("mouseleave", function () {
                  $(_this).popover('hide');
              });
            }else{
              $(".js-closePopover").click(function(event) {
                $(".popover").popover("hide");
              });              
            }
        }).on("mouseleave", function () {
            var _this = this;
            if (!$(_this).hasClass('js-tip-faq')){
              setTimeout(function () {
                  if (!$(".popover:hover").length) {
                      $(_this).popover("hide");
                  }
              }, 300);
            }
        }); 

      var lastScrollTop = 0;
      $(window).scroll(function(event) {
        valorScroll=$(window).scrollTop();

        // añade clase "enpantalla" cuando aparece en pantalla
        $(".js-aparece-scroll").each(function(index, el) {
          if ($(this).offset().top+$(this).height()<=valorScroll+$(window).height()){
            $(this).addClass("enpantalla");
          }else{
            $(this).removeClass("enpantalla");
          }
        });

        //reducido cabecera
        var st = $(this).scrollTop();
        if (st > lastScrollTop && st>30){
          $("body").addClass('reducido');
        } else {
          $("body").removeClass('reducido');
        }
        lastScrollTop = st;
        

      });


      $(".js-mostrarModalCheck").click(function(event) {
        txt=$(this).closest(".form-group").find(".txtModal").html();
        $("#modalAlerta .modal-title").html("");
        $("#modalAlerta .modal-body").html(txt);
        $("#modalAlerta").modal();        
      });


      /**
       * LISTENER validador formularios
       */
      $('.js-form-validator').validator(customValidations()).on('submit', function (e) {
        if (e.isDefaultPrevented() ) {
          $(this).find('.has-error').each(function(index, element){
            // console.log("hay errores");
          });
        }
      });

      /**
       * LISTENER para mostrar/ocultar objetos
       */
      $(".js-toggle-div").click(function(){
        var obj=$(this).data("toggle-div");
        $(obj).toggleClass("desactivo");
      })

      /**
       * LISTENER menus con ancla
       */
      $(".js-menu-ancla").click(function(e){
        e.preventDefault();
        var ancla=$(this).attr("href");
        var offset=$(this).data("menuancla-offset");
        if(offset=="" || offset==undefined){
          offset=0;
        }
        PLG_scrollTo(1,ancla,0,offset,0);
      })

      /**
       * LISTENER para despliegue menú hamburguesa
       */
      $('#menu_desplegable').on('show.bs.collapse', function () {
        PLG_showMenu();
        PLG_closeModalLogin();
      });

      $("#menu_desplegable .close").click(function(){
        PLG_closeMenu();
      });

      /**
       * LISTENER para mostrar modal login
       */
      $(".js-btn-header-login").click(function(){
        if($("#menu_desplegable").hasClass("show")){
          PLG_closeMenu(function(){
             PLG_toggleModalLogin();
          });
        }else{
          PLG_toggleModalLogin();
        }
      });

      /**
       * LISTENER para mostrar modal alerta
       */
      $(".js-modal-alerta").click(function(e){
        e.preventDefault();
        PLG_showModalAlerta($(this).data("alerta-titulo"),$(this).data("alerta-texto"));
      });

      /**
       * LISTENER cierre cookies
       */
      $("#modal-cookies .close").click(function(){
        $("#modal-cookies").fadeOut();
        setCookie("congreso.aslan.com-cookies", "true", 365);
      });
      checkCookie();

      /**
       * LISTENER copiar de un input
       */
      $(".js-btn-copy").click(function() {
          var target=$(this).data("target-copy");
          copyToClipboard(document.getElementById(target));
      });  

      /**
       * LISTENER validador formularios
       */
      $('.js-form-validator-newsletter').validator(customValidations()).on('submit', function (e) {
        if (e.isDefaultPrevented() ) {
          $(this).find('.has-error').each(function(index, element){
            
          });

          $("#modalAlerta .modal-title").html("Lo sentimos");
          $("#modalAlerta .modal-body").html("Comprueba que has rellenado todos los campos obligatorios para continuar.");
          $("#modalAlerta").modal();

        }else{
          e.preventDefault();

          $.ajax({
            method: "POST",
            url: global.ajax_url,
            data: $(this).serialize(),
            dataType: "json",
          })
            .done(function( msg ) {

              console.log("siiii");
              console.log(msg);

              $("#modalAlerta .modal-title").html(msg.titulo);
              $("#modalAlerta .modal-body").html(msg.mensaje);
              $("#modalAlerta").modal();

          });

        }
      });



      /** LISTENER filtro modulo empresas */
      $(".js-mod1Org-area").click(function(event) {

        //jQuery( "#listaEmpresasMod1 .empresaMod1:visible" ).slice( 2, 4 ).css( "background-color", "red" );

        $(this).parent().parent().find("li a").removeClass("activo");
        $(this).addClass("activo");

        $("#listaEmpresasMod1 .empresaMod1").hide();

        var desde=0;
        var hasta=parseInt(desde,10)+parseInt($("#listaEmpresasMod1").attr("data-ppp"),10);
        var cuantos=0;

        if ($(this).attr("data-area")==""){

          $("#listaEmpresasMod1 .empresaMod1").slice(desde,hasta).show();
          cuantos=$("#listaEmpresasMod1 .empresaMod1").length;

        }else{

          $("#listaEmpresasMod1 .empresaMod1.areaMod1_"+$(this).attr("data-area")).slice(desde,hasta).show();
          cuantos=$("#listaEmpresasMod1 .empresaMod1.areaMod1_"+$(this).attr("data-area")).length;

        }


        //pongo el área activa
        $("#listaEmpresasMod1").attr("data-area",$(this).attr("data-area"));

        //pongo siempre a página 1
        $("#listaEmpresasMod1").attr("data-pagina","1");


        //y pongo el número de páginas que corresponden visibles.
        jQuery( "#listaEmpresasMod1 .paginacion li" ).hide();
        jQuery( "#listaEmpresasMod1 .paginacion li a" ).removeClass("activo");
        jQuery( "#listaEmpresasMod1 .paginacion li" ).slice( 0, Math.ceil(cuantos/$("#listaEmpresasMod1").attr("data-ppp")) ).show();
        jQuery( "#listaEmpresasMod1 .paginacion li:eq(0) a" ).addClass("activo");


      });

      $(".js-mod1Org-pag").click(function(event) {

        //pongo siempre a página que sea
        $("#listaEmpresasMod1").attr("data-pagina",$(this).attr("data-pag"));
        //y pongo clase activa
        jQuery( "#listaEmpresasMod1 .paginacion li a" ).removeClass("activo");
        jQuery( "#listaEmpresasMod1 .paginacion li:eq("+($(this).attr("data-pag")-1)+") a" ).addClass("activo");

        var desde=($(this).attr("data-pag")-1)*$("#listaEmpresasMod1").attr("data-ppp");
        var hasta=parseInt(desde,10)+parseInt($("#listaEmpresasMod1").attr("data-ppp"),10);

        if ($("#listaEmpresasMod1").attr("data-area")==""){

          jQuery( "#listaEmpresasMod1 .empresaMod1").hide();
          jQuery( "#listaEmpresasMod1 .empresaMod1" ).slice( desde, hasta ).show();

        }else{

          jQuery( "#listaEmpresasMod1 .empresaMod1").hide();
          $("#listaEmpresasMod1 .empresaMod1.areaMod1_"+$("#listaEmpresasMod1").attr("data-area")).slice(desde,hasta).show();

        }


      });

      $(".js-mod1Org-pag-ant").click(function(event) {

        if (parseInt($("#listaEmpresasMod1").attr("data-pagina"),10)>0){
          $("#listaEmpresasMod1 .paginacion .pag_"+(parseInt($("#listaEmpresasMod1").attr("data-pagina"),10)-1)).trigger("click");
        }

      });


      $(".js-mod1Org-pag-sig").click(function(event) {

        var siguiente=parseInt($("#listaEmpresasMod1").attr("data-pagina"),10)+1;

        if ($("#listaEmpresasMod1 .paginacion li").length >= siguiente && $("#listaEmpresasMod1 .paginacion .pag_"+siguiente).is(":visible")){

          $("#listaEmpresasMod1 .paginacion .pag_"+(parseInt($("#listaEmpresasMod1").attr("data-pagina"),10)+1)).trigger("click");
        }

      });      





      /** LISTENER filtro modulo empresas */
      $(".js-filtroEmpresas").change(function(event) {
        var sectionPadre=$(this).closest("section");
        console.log("filtro CAT > "+sectionPadre.find("#filtroCategoria").val())
        console.log("filtro TAG > "+sectionPadre.find("#filtroTag").val());
        console.log("padre > "+sectionPadre.find("*[data-vista]").data("vista"));
        if (sectionPadre.find("#filtroCategoria").val()=="" && (sectionPadre.find("#filtroTag").val()=="" || sectionPadre.find("#filtroTag").val()==null)){
          sectionPadre.find(".item-listado").show();
          console.log("###muestero todos");
        }else{
          sectionPadre.find(".item-listado").hide();
          console.log("###oculto todos");

          //muestro los sectores
          if (sectionPadre.find("#filtroCategoria").val()!=""){
            sectionPadre.find(".item-listado.cat_"+sectionPadre.find("#filtroCategoria").val()).show();
            console.log("###muestro x categoria");
          }

          //muestro las áreas
          if (sectionPadre.find("#filtroTag").val()!=""){
            console.log("###muestro x tag");
            sectionPadre.find(".item-listado.tag_"+sectionPadre.find("#filtroTag").val()).show();
          }          


        }

      });


      /** INSCRIPCIONES */

      var timeoutEmailInscripcion;
      $("#formInscripcion #inscripcionEmail").on("keyup blur",function(event) {

        clearTimeout(timeoutEmailInscripcion);
        console.log("borro timeout");
        //Si está readonly es porque es una precarga entonces no miro nada.
        if ($("#formInscripcion #inscripcionEmail").attr('readonly')=='readonly' || $("#formInscripcion #inscripcionEmail").attr("data-precarga")=="true" ) {


        }else{

          clearTimeout(timeoutEmailInscripcion);
          timeoutEmailInscripcion=setTimeout(function(){
          

            $("#capaTapadorFormPaso1").fadeIn();

            $.ajax({
              method: "POST",
              url: global.ajax_url,
              dataType: "json",
              data: { action: "existeMailUsuarioAjax", email: $("#formInscripcion #inscripcionEmail").val() }
            })
            .done(function( msg ) {

              $("#capaTapadorFormPaso1").fadeOut();

              if (msg.error=="OK"){

                // 080323 PACO PIDE SIMPLEMENTE MOSTRAR EL LOGIN
                // $("#modalClaveInscripcion .paso1ClaveRellenado").show();
                // $("#modalClaveInscripcion .paso2ClaveRellenado").hide();
                // $("#modalClaveInscripcion .paso2bClaveRellenado").hide();
                // $("#modalClaveInscripcion .paso2ClaveRellenado .form-control-feedback").hide();
                // $("#modalClaveInscripcion .paso2bClaveRellenado .form-control-feedback").hide();
                // $("#modalClaveInscripcion").modal({backdrop: 'static',keyboard: false});
                
                jQuery("#modalLogin .modal-title").html("Ya estás registrado");
                jQuery("#modalLogin .modal-body .textoLogin").html("Hemos detectado que ya estás registrado en la plataforma. Por favor, introduce tu contraseña para continuar con el proceso de inscripción.<br/><br/>");                
                jQuery("#modalLogin").modal("show");
                jQuery("#loginEmail").val($("#formInscripcion #inscripcionEmail").val());

                // ********************************************

              }

              if (msg.error=="KO" && msg.tipo=="NOPRO"){
                if ($("#formInscripcion #inscripcionEmail").attr('data-avisado')!="true"){
                  //console.log("no lo había avisado, muestro nopro");
                  $("#formInscripcion #inscripcionEmail").attr('data-avisado',"true");
                  $('#modalAlerta').mostrar_msg(msg.titulo, msg.mensaje);
                }else{
                  //console.log("ya había avisado no pro avisado")
                }
                
              }

            });

          },1000);

        }

      });


      $("#formInscripcion #inscripcionPais").change(function(event) {

        if ($(this).val()=="españa"){
          $("#formInscripcion #inscripcionPrefijoTelefono").val("34");
          $("#formInscripcion #inscripcionPrefijoMovil").val("34");
          $("#formInscripcion #inscripcionPrefijoTelefono").prop('tabIndex', -1);
          $("#formInscripcion #inscripcionPrefijoMovil").prop('tabIndex', -1);      
          $("#formInscripcion #grupoInscripcionProvinciaCampo").hide();
          $("#formInscripcion #inscripcionProvincia").val("-");
          $("#formInscripcion #grupoInscripcionProvinciaSelect").show();

        }else{
          $("#formInscripcion #inscripcionPrefijoTelefono").val("");
          $("#formInscripcion #inscripcionPrefijoMovil").val("");
          $("#formInscripcion #inscripcionPrefijoTelefono").prop('tabIndex', "");
          $("#formInscripcion #inscripcionPrefijoMovil").prop('tabIndex', "");          
          $("#formInscripcion #grupoInscripcionProvinciaCampo").show();
          $("#formInscripcion #inscripcionProvincia").val("");
          $("#formInscripcion #grupoInscripcionProvinciaSelect").hide();
        }

      });


      $("#formInscripcionPaso2 #inscripcionSectorActividad").change(function(event) {
        if ($(this).val()=="T.I.C"){
          $("#formInscripcionPaso2 .contenedorSectorTic").show();
          $("#formInscripcionPaso2 #inscripcionSectorTIC").trigger("chosen:updated");
        }else{
          $("#formInscripcionPaso2 .contenedorSectorTic").hide();
          $("#formInscripcionPaso2 #inscripcionSectorTIC").val("");
          $("#formInscripcionPaso2 #inscripcionSectorTIC").trigger("chosen:updated");
        }
      });


      $(".js-claveRellenadoInscripcion").click(function(event) {

          objetoClic=$(this);

          $.ajax({
            method: "POST",
            url: global.ajax_url,
            dataType: "json",
            data: { action: "enviaMailClave", email: $("#formInscripcion #inscripcionEmail").val(), idpi: objetoClic.attr("data-idp") }
          })
          .done(function( msg ) {

            if (msg.error=="OK"){

              $("#modalClaveInscripcion").modal("hide");
              /*
              $("#modalClaveInscripcion .paso1ClaveRellenado").hide();
              $("#modalClaveInscripcion .paso2ClaveRellenado").show();
              */
              jQuery('#modalAlerta').mostrar_msg("<i class='fa fa-exclamation-triangle' aria-hidden='true'></i>", "Le hemos enviado un enlace a su correo desde el que realizar la inscripción en un clic");
              setTimeout(function(){
                document.location.href="/";
              },2000)
            }

            if (msg.error=="KO_EXISTE"){
              jQuery("#modalLogin .loginRedirect-url").attr("value",'/mis-inscripciones/');
              jQuery('#modalAlerta').mostrar_msg("<i class='fa fa-exclamation-triangle' aria-hidden='true'></i>", "Hemos comprobado que ya existe una inscripción para este correo electrónico. <a href='javascript:;' onclick=\"$('#modalAlerta').modal('hide'); aslan_login(false,'','')\" >Acceda a su área privada > Inscripciones<a/>");
            }


          });

      });

      $(".js-loginRellenadoInscripcion").click(function(event) {
          $("#modalClaveInscripcion .paso1ClaveRellenado").hide();
          $("#modalClaveInscripcion .paso2bClaveRellenado").show();
      });

      $(".js-enviarClaveRellenadoInscripcion").click(function(event) {

          $.ajax({
            method: "POST",
            url: global.ajax_url,
            dataType: "json",
            data: { action: "getDatosUsuarioClave", tipo:"clave", email: $("#formInscripcion #inscripcionEmail").val(), clave: $("#inscripcionClaveRellenado").val() }
          })
          .done(function( msg ) {

            if (msg.error=="OK"){

              $("#inscripcionEmail").prop('readonly', true);

              $("#inscripcionNombre").val(msg.inscripcionNombre);
              $("#inscripcionApellidos").val(msg.inscripcionApellidos);
              $("#inscripcionEmpresa").val(msg.inscripcionEmpresa);
              $("#inscripcionCargo").val(msg.inscripcionCargo);
              if (msg.inscripcionTelefono!="" && msg.inscripcionTelefono != null){
                tel=msg.inscripcionTelefono.split(" ");
                if (tel.length>1){
                  $("#inscripcionPrefijoTelefono").val(tel[0]);
                  $("#inscripcionTelefono").val(tel[1]);
                }else{
                  $("#inscripcionTelefono").val(msg.inscripcionTelefono);
                }
              }

              if (msg.inscripcionMovil!="" && msg.inscripcionMovil != null){
                mvl=msg.inscripcionMovil.split(" ");
                if (mvl.length>1){
                  $("#inscripcionPrefijoMovil").val(mvl[0]);
                  $("#inscripcionMovil").val(mvl[1]);
                }else{
                  $("#inscripcionMovil").val(msg.inscripcionMovil);
                }
              }
              $("#inscripcionDireccion").val(msg.inscripcionDireccion);
              $("#inscripcionLocalidad").val(msg.inscripcionLocalidad);
              $("#inscripcionProvincia").val(msg.inscripcionProvincia);
              $("#inscripcionCP").val(msg.inscripcionCP);
              $("#inscripcionPais").val(msg.inscripcionPais);
              //console.log("pais:::"+$("#inscripcionPais").val());
              $("#inscripcionPais").trigger("chosen:updated");          

              /*
              if (msg.inscripcionOptinSMS==true){
                $("#inscripcionOptinUsuario").iCheck('check');
              }else{
                $("#inscripcionOptinUsuario").iCheck('uncheck');
              }

              if (msg.inscripcionOptinSMS==true){
                $("#inscripcionOptinPatrocinadores").iCheck('check');
              }else{
                $("#inscripcionOptinPatrocinadores").iCheck('uncheck');
              }

              if (msg.inscripcionOptinSMS==true){
                $("#inscripcionOptinSMS").iCheck('check');
              }else{
                $("#inscripcionOptinSMS").iCheck('uncheck');
              }
              */                          

              $("#modalClaveInscripcion").modal("hide");


              //Datos de claves para que posteriormente pueda actualizar el usuario en paso 1
              $("#formInscripcion .inscripcionIDU").val(msg.inscripcionIDU);
              $("#formInscripcion .inscripcionClaveU").val(msg.inscripcionClaveU);


              //ahora relleno los posibles del paso 2
              if (msg.inscripcionTipoEmpresa!=""){
                $("#formInscripcionPaso2 #inscripcionTipo_Empresa").val(msg.inscripcionTipoEmpresa);
                $("#formInscripcionPaso2 #inscripcionTipo_Empresa").trigger("chosen:updated");    
              }

              if (msg.inscripcionAreaFuncional!=""){
                $("#formInscripcionPaso2 #inscripcionArea_Funcional").val(msg.inscripcionAreaFuncional);
                $("#formInscripcionPaso2 #inscripcionArea_Funcional").trigger("chosen:updated");    
              }

              if (msg.inscripcionNivelDecision!=""){
                $("#formInscripcionPaso2 #inscripcionNivel_decision").val(msg.inscripcionNivelDecision);
                $("#formInscripcionPaso2 #inscripcionNivel_decision").trigger("chosen:updated");    
              }

              if (msg.inscripcionNivelResponsabilidad!=""){
                $("#formInscripcionPaso2 #inscripcionNivel_responsabilidad").val(msg.inscripcionNivelResponsabilidad);
                $("#formInscripcionPaso2 #inscripcionNivel_responsabilidad").trigger("chosen:updated");    
              }              

              if (msg.inscripcionEmpleados!=""){
                $("#formInscripcionPaso2 #inscripcionEmpleados").val(msg.inscripcionEmpleados);
                $("#formInscripcionPaso2 #inscripcionEmpleados").trigger("chosen:updated");    
              }

              if (msg.inscripcionPCs!=""){
                $("#formInscripcionPaso2 #inscripcionPCs").val(msg.inscripcionPCs);
                $("#formInscripcionPaso2 #inscripcionPCs").trigger("chosen:updated");    
              }

              if (msg.inscripcionPortatiless!=""){
                $("#formInscripcionPaso2 #inscripcionPortatiles").val(msg.inscripcionPortatiless);
                $("#formInscripcionPaso2 #inscripcionPortatiles").trigger("chosen:updated");    
              }

              if (msg.inscripcionTablets!=""){
                $("#formInscripcionPaso2 #inscripcionTablets").val(msg.inscripcionTablets);
                $("#formInscripcionPaso2 #inscripcionTablets").trigger("chosen:updated");    
              }

              if (msg.inscripcionFacturacion!=""){
                $("#formInscripcionPaso2 #inscripcionFacturacion").val(msg.inscripcionFacturacion);
                $("#formInscripcionPaso2 #inscripcionFacturacion").trigger("chosen:updated");    
              }

              if (msg.inscripcionSectorActividad!=""){
                $("#formInscripcionPaso2 #inscripcionSectorActividad").val(msg.inscripcionSectorActividad);
                $("#formInscripcionPaso2 #inscripcionSectorActividad").trigger("chosen:updated");    
              }

              if (msg.inscripcionSectorActividad=="T.I.C"){

                $("#formInscripcionPaso2 .contenedorSectorTic").show();
                if (msg.inscripcionSectorTIC!=""){
                  $("#formInscripcionPaso2 #inscripcionSectorTIC").val(msg.inscripcionSectorTIC);
                  $("#formInscripcionPaso2 #inscripcionSectorTIC").trigger("chosen:updated");    
                }

              }

              if (msg.inscripcionAreasTecnologicas != null){
                arrayAreasTecnologicas=msg.inscripcionAreasTecnologicas;
                for (i=0;i<arrayAreasTecnologicas.length;i++){
                  $("#formInscripcionPaso2 input[value='"+arrayAreasTecnologicas[i]+"']").iCheck('check');
                }
              }

              /*
              console.log("###redirect paso 3 de usuario logado y registrado")
              //si está logado y registrado, hago redirect a paso 3
              jQuery('#modalAlerta').mostrar_msg(msg.titulo, msg.mensaje);
              jQuery('#formInscripcion .btn[type="submit"]').trigger("click");
              */


            }else{

              if (msg.error=="KOCLAVE"){
                $("#modalClaveInscripcion .paso2ClaveRellenado .form-control-feedback").show();
              }else{
                jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);
              }

            }


          });

      });    



      $(".js-enviarLoginRellenadoInscripcion").click(function(event) {

          $.ajax({
            method: "POST",
            url: global.ajax_url,
            dataType: "json",
            data: { id_pagina_inscripcion:$(this).data("idp"),action: "getDatosUsuarioClave", tipo:"login", email: $("#formInscripcion #inscripcionEmail").val(), clave: $("#inscripcionPassRellenado").val() }
          })
          .done(function( msg ) {

            if (msg.error=="OK"){

              $("#inscripcionEmail").prop('readonly', true);

              $("#inscripcionNombre").val(msg.inscripcionNombre);
              $("#inscripcionApellidos").val(msg.inscripcionApellidos);
              $("#inscripcionEmpresa").val(msg.inscripcionEmpresa);
              $("#inscripcionCargo").val(msg.inscripcionCargo);

              tel=msg.inscripcionTelefono.split(" ");
              if (tel.length>1){
                $("#inscripcionPrefijoTelefono").val(tel[0]);
                $("#inscripcionTelefono").val(tel[1]);
              }else{
                $("#inscripcionTelefono").val(msg.inscripcionTelefono);
              }

              mvl=msg.inscripcionMovil.split(" ");
              if (mvl.length>1){
                $("#inscripcionPrefijoMovil").val(mvl[0]);
                $("#inscripcionMovil").val(mvl[1]);
              }else{
                $("#inscripcionMovil").val(msg.inscripcionMovil);
              }

              $("#inscripcionDireccion").val(msg.inscripcionDireccion);
              $("#inscripcionLocalidad").val(msg.inscripcionLocalidad);
              $("#inscripcionProvincia").val(msg.inscripcionProvincia);
              $("#inscripcionCP").val(msg.inscripcionCP);
              $("#inscripcionPais").val(msg.inscripcionPais);

              //console.log("pais:::"+$("#inscripcionPais").val());
              $("#inscripcionPais").trigger("chosen:updated");


              /*
              if (msg.inscripcionOptinSMS==true){
                $("#inscripcionOptinUsuario").iCheck('check');
              }else{
                $("#inscripcionOptinUsuario").iCheck('uncheck');
              }

              if (msg.inscripcionOptinSMS==true){
                $("#inscripcionOptinPatrocinadores").iCheck('check');
              }else{
                $("#inscripcionOptinPatrocinadores").iCheck('uncheck');
              }

              if (msg.inscripcionOptinSMS==true){
                $("#inscripcionOptinSMS").iCheck('check');
              }else{
                $("#inscripcionOptinSMS").iCheck('uncheck');
              }
              */                       

              $("#modalClaveInscripcion").modal("hide");


              //Datos de claves para que posteriormente pueda actualizar el usuario en paso 1
              $("#formInscripcion .inscripcionIDU").val(msg.inscripcionIDU);
              $("#formInscripcion .inscripcionClaveU").val(msg.inscripcionClaveU);

              //ahora relleno los posibles del paso 2
              if (msg.inscripcionTipoEmpresa!=""){
                $("#formInscripcionPaso2 #inscripcionTipo_Empresa").val(msg.inscripcionTipoEmpresa);
                $("#formInscripcionPaso2 #inscripcionTipo_Empresa").trigger("chosen:updated");    
              }

              if (msg.inscripcionAreaFuncional!=""){
                $("#formInscripcionPaso2 #inscripcionArea_Funcional").val(msg.inscripcionAreaFuncional);
                $("#formInscripcionPaso2 #inscripcionArea_Funcional").trigger("chosen:updated");    
              }

              if (msg.inscripcionNivelDecision!=""){
                $("#formInscripcionPaso2 #inscripcionNivel_decision").val(msg.inscripcionNivelDecision);
                $("#formInscripcionPaso2 #inscripcionNivel_decision").trigger("chosen:updated");    
              }

              if (msg.inscripcionNivelResponsabilidad!=""){
                $("#formInscripcionPaso2 #inscripcionNivel_responsabilidad").val(msg.inscripcionNivelResponsabilidad);
                $("#formInscripcionPaso2 #inscripcionNivel_responsabilidad").trigger("chosen:updated");    
              }              

              if (msg.inscripcionEmpleados!=""){
                $("#formInscripcionPaso2 #inscripcionEmpleados").val(msg.inscripcionEmpleados);
                $("#formInscripcionPaso2 #inscripcionEmpleados").trigger("chosen:updated");    
              }

              if (msg.inscripcionPCs!=""){
                $("#formInscripcionPaso2 #inscripcionPCs").val(msg.inscripcionPCs);
                $("#formInscripcionPaso2 #inscripcionPCs").trigger("chosen:updated");    
              }

              if (msg.inscripcionPortatiless!=""){
                $("#formInscripcionPaso2 #inscripcionPortatiles").val(msg.inscripcionPortatiless);
                $("#formInscripcionPaso2 #inscripcionPortatiles").trigger("chosen:updated");    
              }

              if (msg.inscripcionTablets!=""){
                $("#formInscripcionPaso2 #inscripcionTablets").val(msg.inscripcionTablets);
                $("#formInscripcionPaso2 #inscripcionTablets").trigger("chosen:updated");    
              }

              if (msg.inscripcionFacturacion!=""){
                $("#formInscripcionPaso2 #inscripcionFacturacion").val(msg.inscripcionFacturacion);
                $("#formInscripcionPaso2 #inscripcionFacturacion").trigger("chosen:updated");    
              }

              if (msg.inscripcionSectorActividad!=""){
                $("#formInscripcionPaso2 #inscripcionSectorActividad").val(msg.inscripcionSectorActividad);
                $("#formInscripcionPaso2 #inscripcionSectorActividad").trigger("chosen:updated");    
              }

              if (msg.inscripcionSectorActividad=="T.I.C"){

                $("#formInscripcionPaso2 .contenedorSectorTic").show();
                if (msg.inscripcionSectorTIC!=""){
                  $("#formInscripcionPaso2 #inscripcionSectorTIC").val(msg.inscripcionSectorTIC);
                  $("#formInscripcionPaso2 #inscripcionSectorTIC").trigger("chosen:updated");    
                }

              }

              if ( msg.inscripcionAreasTecnologicas != null){
                arrayAreasTecnologicas=msg.inscripcionAreasTecnologicas;
                for (i=0;i<arrayAreasTecnologicas.length;i++){
                  $("#formInscripcionPaso2 input[value='"+arrayAreasTecnologicas[i]+"']").iCheck('check');
                }
              } 

              // $("#modalClaveInscripcion").modal("hide");

              if(msg.ya_inscrito==1){
                jQuery('#modalAlerta').mostrar_msg("Vale!", "Estamos redirigiéndole a su página de inscripción...");
                document.location.href=msg.url_redirect;
              }else{
                setTimeout(function(){
                jQuery('#modalAlerta').mostrar_msg("Vale!", "Hemos precargado sus datos de usuario para que valide y continúe la inscripción.");
                },1000)
              }


            }else{

              if (msg.error=="KOPASS"){
                $("#modalClaveInscripcion .paso2bClaveRellenado .form-control-feedback").show();
              }else{
                jQuery('#modalAlerta').mostrar_msg(result['titulo'], result['mensaje']);
              }

            }

          });

      })  


      $(".js-btn-actualizarPlan").click(function(event) {


        if ($(this).attr("data-desactivado")!="true"){
          datos = { 
            action:'getPaypalURL', 
            cantidad:$(this).attr("data-importe"), 
            inscripcion:$(this).attr("data-idInscripcion"),
            tipo:$(this).attr("data-tipo"),
            idPInscripcion: $(this).attr("data-idpinscripcion"),
            idInvitacion: $(this).attr("data-idinvitacion"),
            codigo: $(this).attr("data-codigo"),
            idCodigo: $(this).attr("data-idcodigo"),
            clave:$(this).attr("data-claveActualizar")
          };

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: datos,
            dataType: "json"
          })
          .done(function(data) {

            if (data.error=="OK"){
              console.log("success");
              if (data.irpaypal){
                self.location.href = data.url;
              }else{
                $("#graciasInscripcion .cont-ok .nombreInscripcionGracias").html(data.nombre);
                $("#graciasInscripcion .cont-ok .idInscripcionGracias").html(data.idi);
                $("#graciasInscripcion .cont-ok").show();
                $("#graciasInscripcion .cont-ko").hide();
                
                //JB 270524 se auto clicka para que actualice.
                // swiperInscripcion.slideTo(3);
                // PLG_scrollTo(0.5,"#section-inscripcion",0.5,-130);

                //hago llamada ajax para la generación del pase.
                if (typeof data.urlcreacionpase !== 'undefined' && data.urlcreacionpase!=""){
                  $.ajax({
                    url: data.urlcreacionpase,
                  }).done(function() {
                  });
                }    

                //JB 270524 se auto clicka para que actualice.
                var urlactual="";
                if (self.location.search) {
                  urlactual=self.location.href+="&actualizado=true";
                } else {
                  urlactual=self.location.href+="?actualizado=true";
                }                

                self.location.href=urlactual;

              }
            }else{
              console.log("error controlado");
              $('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);
            }
          })
          .fail(function() {
            console.log("error");
          });

        }

      });


      /** FIN INSCRIPCIONES */


      /** LOGIN, RECORDAR Y CAMBIAR PASS */


      $(".js-boton-logon").click(function(event) {
        aslan_recordar();
      });


      $(".js-logout").click(function(event) {
          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "aslan_logout"},
            dataType: "json"
          })
          .done(function(data) {

            if (data.error=="OK"){
              document.location.href="/";
            }else{
              console.log("error controlado");
              $('#modalAlerta').mostrar_msg("Lo sentimos","Ha ocurrido un error, inténtalo de nuevo más tarde.");
            }
          })
          .fail(function() {
            console.log("error");
          });
      });


      $(".js-boton-recordarPass").click(function(event) {

        console.log("siii");

        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(jQuery("#recordarpassEmail").val()).toLowerCase())){
          url="http://www.aslan.es/Acceder?o="+jQuery(this).attr("data-ocultar")+"&Autentificando=Si&Usuario="+jQuery("#recordarpassEmail").val()+"&Autentificar=Si";
          window.open(url);
          jQuery("#recordarpassEmail").closest(".form-group").removeClass("has-error");
          jQuery("#modalRecordarpass").modal("hide");
        }else{
          jQuery("#recordarpassEmail").closest(".form-group").addClass("has-error");
        }
        
        
      });

      $(".js-login").click(function(event) {
        aslan_login(false,'','');
      });

      /** FIN LOGIN, RECORDAR Y CAMBIAR PASS */



      /** CONFERENCIAS */


      $(".js-cambiarSalaConferencias").click(function(event) {
        idSala=$(this).attr("data-idSala");
        nombre=$(this).find(".nombre").html();
        desc=$(this).find(".desc").html();

        $(".sala-activa .nombre").html(nombre);
        $(".sala-activa .desc").html(desc);
        $(".salaConferencias").not("#salaConferencias_"+idSala).fadeOut(function(){
          $("#salaConferencias_"+idSala).fadeIn();
        })
      });


      $(".js-masInformacionConferencia").click(function(event) {
        jQuery('#modalMasinfoConferencias').mostrar_msg("Más información", $(this).parent().find(".js-contentInformacionConferencia").html());
      });

      $(".js-bioPonenteConferencia").click(function(event) {
        jQuery('#modalBioConferencias').mostrar_msg($(this).parent().find(".cont-nombre").html(), $(this).parent().find(".js-contentBioPonente").html());
      });

      $(".js-extractoConferencia").click(function(event) {
        jQuery('#modalBioConferencias').mostrar_msg($(this).parent().find(".js-tituloExtractoConferencia").html(), $(this).parent().find(".js-contentExtractoConferencia").html());
      });            

      /** FIN CONFERENCIAS */



      /** CONCURSOS */

      $(".js-masInfoCandidatura").click(function(event) {
        $('#modalConcursos').mostrar_msg($(this).parent().find(".tit").html(),$(this).parent().find(".desc").html());
      });

      $(".js-desplegarBio").mouseover(function(event) {
        $(this).closest('.elementoAgenda').find(".js-biodesplegable").stop(true, false).slideDown();
      });

      $(".js-desplegarBio").mouseleave(function(event) {
        $(this).closest('.elementoAgenda').find(".js-biodesplegable").stop(true, false).slideUp();
      });

      $(".js-desplegarInfo").mouseover(function(event) {
        $(this).closest('.elementoAgenda').find(".js-infodesplegable").stop(true, false).slideDown();
      });

      $(".js-desplegarInfo").mouseleave(function(event) {
        $(this).closest('.elementoAgenda').find(".js-infodesplegable").stop(true, false).slideUp();
      });                 


      if ($(".modulo-concurso").length > 0){

        console.log("siiiiii: "+window.location.hash.substring(0, 12));
        if(window.location.hash && window.location.hash.substring(0, 12)=="#candidatura" && $(window.location.hash).length > 0) {

          $(window.location.hash).addClass("destacada");
          $('html, body').animate({
              scrollTop: $(window.location.hash).offset().top - $("header").height() -10
          }, 1000);

        }

      }      


      /** FIN CONCURSOS */



      /** LISTADO NOTICIAS FILTRADO */

      $(".js-noticiasFiltradas-filtro").chosen().change(function(){

        if (jQuery(this).closest("#section-listado-noticias").find('.js-paginacion').length>0){

          var datosTemp=JSON.parse(jQuery(this).closest("#section-listado-noticias").find('.js-paginacion').attr("data-filtros"));
          datosTemp.pagina=1;
          jQuery(this).closest("#section-listado-noticias").find('.js-paginacion').attr("data-filtros",JSON.stringify(datosTemp));

        }

        $(this).closest("#section-listado-noticias").find(".js-contenedorNoticiasFiltradas").attr("data-pagina","1");
        $(this).closest("#section-listado-noticias").find(".js-paginacion").attr("data-pagina","1");
        $(this).closest("#section-listado-noticias").find(".js-contenedorNoticiasFiltradas").trigger('refrescar');        

      });      

      $(".js-contenedorNoticiasFiltradas").on('refrescar', function(event) {


          console.log("refresco!");

          if (jQuery(this).find('.js-paginacion').length>0){
            datos=JSON.parse(jQuery(this).find('.js-paginacion').attr("data-filtros"));
          }
          datos.action="AjaxListarNoticiasFiltradas";

          datos.filtro=$(".js-noticiasFiltradas-filtro").val();

          el=jQuery(this);

          el.find(".js-spinner-holder").fadeIn();

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: datos
          })
          .done(function(html) {

            el.html(html);

            el.find(".js-spinner-holder").fadeOut();

            el.find(".js-anteriorPagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                if (paginaActual>1){

                  datos.pagina=parseInt(datos.pagina,10)-1;

                }

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorNoticiasFiltradas").trigger("refrescar");
                

                PLG_scrollTo(0.5,"."+el.closest("#section-listado-noticias").attr("data-ancla"),0,-100);

            });

            el.find(".js-siguientePagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                if (paginaActual<totalPaginas){

                  datos.pagina=parseInt(datos.pagina,10)+1;

                }

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorNoticiasFiltradas").trigger("refrescar");

                PLG_scrollTo(0.5,"."+el.closest("#section-listado-noticias").attr("data-ancla"),0,-100);

            });

            el.find(".js-irPagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                datos.pagina=jQuery(this).attr("data-pagina");

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorNoticiasFiltradas").trigger("refrescar");


                PLG_scrollTo(0.5,"."+el.closest("#section-listado-noticias").attr("data-ancla"),0,-100);

            });


          })
          .fail(function() {
            console.log("error");
          });

      });

      $(".js-contenedorNoticiasFiltradas .js-anteriorPagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          if (paginaActual>1){

            datos.pagina="";
            datos.pagina=parseInt(datos.pagina,10)-1;

          }

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorNoticiasFiltradas").trigger("refrescar");


          PLG_scrollTo(0.5,"."+jQuery(this).closest("#section-listado-noticias").attr("data-ancla"),0,-100);


      });

      $(".js-contenedorNoticiasFiltradas .js-siguientePagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          console.log(datos);

          if (paginaActual<totalPaginas){

            datos.pagina=parseInt(datos.pagina,10)+1;

          }

          console.log(datos);

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorNoticiasFiltradas").trigger("refrescar");

          PLG_scrollTo(0.5,"."+jQuery(this).closest("#section-listado-noticias").attr("data-ancla"),0,-100);


      });

      $(".js-contenedorNoticiasFiltradas .js-irPagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          datos.pagina=jQuery(this).attr("data-pagina");

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorNoticiasFiltradas").trigger("refrescar");

          PLG_scrollTo(0.5,"."+jQuery(this).closest("#section-listado-noticias").attr("data-ancla"),0,-100);


      });             

      /** FIN LISTADO NOTICIAS FILTRADO */  


      /** ENTRADAS */  

      $(".js-valorarPublicacion").click(function(event) {


          datos = { 
            action:'valorarPublicacion', 
            publicacion:$(this).attr("data-publicacion"),
            usuario:$(this).attr("data-usuario"),
            valor: $(this).attr("data-valor")
          };

          idObjeto=$(this).attr("data-publicacion");

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: datos,
            dataType: "json"
          })
          .done(function(data) {

            if (data.error=="OK"){
              console.log("success");
              $('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);

              if (parseInt(data.datos.media,10) >=5 ){

                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(0)").removeClass('fa-star-o');
                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(0)").addClass('fa-star');
                                                              
              }

              if (parseInt(data.datos.media,10) >=4 ){

                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(1)").removeClass('fa-star-o');
                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(1)").addClass('fa-star');
                                                              
              }

              //console.log(data.datos.media);

              if (parseInt(data.datos.media,10) >=3 ){

                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(2)").removeClass('fa-star-o');
                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(2)").addClass('fa-star');
                                                              
              }

              if (parseInt(data.datos.media,10) >=2 ){

                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(3)").removeClass('fa-star-o');
                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(3)").addClass('fa-star');
                                                              
              }

              if (parseInt(data.datos.media,10) >=1 ){

                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(4)").removeClass('fa-star-o');
                $(".js-valoracionPublicacion-"+idObjeto+" .js-valorarPublicacion:eq(4)").addClass('fa-star');
                                                              
              }                                                        

              $(".js-valoracionPublicacion-"+idObjeto);


            }else{
              console.log("error controlado");
              $('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);
            }
          })
          .fail(function() {
            console.log("error");
          });

      });


      $(".js-likePublicacion").click(function(event) {

          el=$(this);

          datos = { 
            action:'likePublicacion', 
            publicacion:$(this).attr("data-publicacion"),
            usuario:$(this).attr("data-usuario")
          };

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: datos,
            dataType: "json"
          })
          .done(function(data) {

            if (data.error=="OK"){

              el.find("span").html(data.total);

              if (parseInt(data.estado,10)==0){
                el.removeClass('megusta');
              }else{
                el.addClass('megusta');
              }
              
            }else{
              console.log("error controlado");
              $('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);
            }
          })
          .fail(function() {
            console.log("error");
          });

      });   


      $(".js-FavPublicacion").click(function(event) {

          el=$(this);

          //si le tengo que hacer desaparecer en caso de quitar, lo hago desaparecer
          if (el.attr("data-hacer_desaparecer")=="true"){

            if (el.hasClass('fa-heart')){
              el.closest(el.attr("data-antecesor")).hide();
            }

          }


          datos = { 
            action:'favPublicacion', 
            publicacion:$(this).attr("data-publicacion"),
            usuario:$(this).attr("data-usuario")
          };

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: datos,
            dataType: "json"
          })
          .done(function(data) {

            if (data.error=="OK"){

              el.find("span").html(data.total);

              if (parseInt(data.estado,10)==0){
                /*
                el.addClass('fa-heart-o');
                el.removeClass('fa-heart');
                */
                $(".favorito_"+el.attr("data-publicacion")).addClass('fa-heart-o');
                $(".favorito_"+el.attr("data-publicacion")).removeClass('fa-heart');

                $(".favoritoOcultar_"+el.attr("data-publicacion")).hide();

              }else{
                /*
                el.removeClass('fa-heart-o');
                el.addClass('fa-heart');
                */
                $(".favorito_"+el.attr("data-publicacion")).removeClass('fa-heart-o');
                $(".favorito_"+el.attr("data-publicacion")).addClass('fa-heart');                

                $(".favoritoOcultar_"+el.attr("data-publicacion")).show();
              }
              
            }else{
              console.log("error controlado");
              $('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);
            }
          })
          .fail(function() {
            console.log("error");
          });

      });         

      /** FIN ENTRADAS */      


      /** PERFIL USUARIOS **/

      $("#formPerfil .js-cambioEmailPerfil").click(function(event) {
        $("#modalCambioEmail").modal("show");
      });

      $("#formPerfil #perfilPais").change(function(event) {

        if ($(this).val()=="españa"){
          $("#formPerfil #perfilPrefijoTelefono").val("34");
          $("#formPerfil #perfilPrefijoMovil").val("34");
          $("#formPerfil #perfilPrefijoTelefono").prop('tabIndex', -1);
          $("#formPerfil #perfilPrefijoMovil").prop('tabIndex', -1);      
          $("#formPerfil #grupoPerfilProvinciaCampo").hide();
          $("#formPerfil #perfilProvincia").val("-");
          $("#formPerfil #grupoPerfilProvinciaSelect").show();

        }else{
          $("#formPerfil #perfilPrefijoTelefono").val("");
          $("#formPerfil #perfilPrefijoMovil").val("");
          $("#formPerfil #perfilPrefijoTelefono").prop('tabIndex', "");
          $("#formPerfil #perfilPrefijoMovil").prop('tabIndex', "");          
          $("#formPerfil #grupoPerfilProvinciaCampo").show();
          $("#formPerfil #perfilProvincia").val("");
          $("#formPerfil #grupoPerfilProvinciaSelect").hide();
        }

      }); 

     $(".js-tabActividad").click(function(event) {
       
        $(".tabActividad").removeClass("active");
        $(this).addClass('active');

        el=$(".elementoActividad_"+$(this).attr("data-id"));
        $(".elementoActividad").fadeOut("fast",function(){

          el.fadeIn("fast");

        })

     });


      if ($(".js-contadorAtras").length > 0){

        var timeoutContadorAtras = setInterval(function(){

          $(".js-contadorAtras").each(function(index, el) {
            
            if ($(this).attr("data-actual")==""){
              $(this).attr("data-actual",$(this).attr("data-fecha"));
            }

            var fecha=$(this).attr("data-fecha");
            var countDownDate = new Date(fecha.replace(' ', 'T')).getTime(); //"Apr 11, 2018 09:00:00"
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            $(this).find(".dias strong").html(days);
            $(this).find(".horas strong").html(hours);
            $(this).find(".minutos strong").html(minutes);
            $(this).find(".segundos strong").html(seconds);

          });

        },1000);

      }


      $(".js-reenviarMailInscripcion").click(function(event) {

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "reenviarMailInscripcion", idi: $(this).attr("data-idi"), idp: $(this).attr("data-idp"), ido:  $(this).attr("data-ido") },
            dataType: "json"
          })
          .done(function(data) {

              $('#modalAlerta').mostrar_msg(data.titulo,data.mensaje);

          })
          .fail(function() {
            console.log("error");
          });

      });

      $(".js-logDescarga").click(function(event) {

          event.preventDefault();

          $(".js-logDescargaAceptada").attr("data-idu", $(this).attr("data-idu"));
          $(".js-logDescargaAceptada").attr("data-ido", $(this).attr("data-ido"));
          $(".js-logDescargaAceptada").attr("data-tipo", $(this).attr("data-tipo"));
          $(".js-logDescargaAceptada").attr("data-idr", $(this).attr("data-idr"));
          $(".js-logDescargaAceptada").attr("data-url", $(this).attr("href")); 

          if ($(this).attr("data-logoempresa")){
            $("#modalOptinEmpresa .modal-header").show();
            $("#modalOptinEmpresa .modal-header .modal-title").html("<img src='"+$(this).attr("data-logoempresa")+"' class='img-fluid'>");
          }else{

          }

          console.log("asdf"+$(this).attr("data-nombreempresa"));

          $("#modalOptinEmpresa .js-nombreOptinEmpresa").html($(this).attr("data-nombreempresa"));

          $("#modalOptinEmpresa").modal({backdrop: 'static', keyboard: false});   

      });

      $(".js-logDescargaAceptada").click(function(event) {
        /* Act on the event */

          event.preventDefault();

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "logDescarga", idu: $(this).attr("data-idu"), ido: $(this).attr("data-ido"), tipo:  $(this).attr("data-tipo"), idr:  $(this).attr("data-idr"), guardarvinculo:"true" },
            dataType: "json"
          })
          .done(function(data) {

          })
          .fail(function() {
          }); 

          $("#modalOptinEmpresa").modal("hide");

          if ($(this).attr("data-url")!="" && $(this).attr("data-url")!="javascript:;"){

            window.open($(this).attr("data-url"));

            window.lintrk('track', { conversion_id: 4833178 });
            ga('send', 'event', 'Descarga', 'Realizada', 'iddocumento', $(this).attr("data-idr"));

          }

      });

      $(".js-logDescargaAceptadaCerrar").click(function(event) {
        /* Act on the event */

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "logDescarga", idu: $(this).closest('#modalOptinEmpresa').find(".js-logDescargaAceptada").attr("data-idu"), ido: $(this).closest('#modalOptinEmpresa').find(".js-logDescargaAceptada").attr("data-ido"), tipo:  $(this).closest('#modalOptinEmpresa').find(".js-logDescargaAceptada").attr("data-tipo"), idr:  $(this).closest('#modalOptinEmpresa').find(".js-logDescargaAceptada").attr("data-idr"), guardarvinculo:"false" },
            dataType: "json"
          })
          .done(function(data) {

          })
          .fail(function() {
          }); 

          window.open($(this).closest('#modalOptinEmpresa').find(".js-logDescargaAceptada").attr("data-url"));

          window.lintrk('track', { conversion_id: 4833178 });
          ga('send', 'event', 'Descarga', 'Realizada', 'iddocumento', $(this).closest('#modalOptinEmpresa').find(".js-logDescargaAceptada").attr("data-idr"));

      });

      $(".js-logVisita").click(function(event) { 

          event.preventDefault();

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "logVisita", idu: $(this).attr("data-idu"), ido: $(this).attr("data-ido"), tipo:  $(this).attr("data-tipo"), idr:  $(this).attr("data-idr"), guardarvinculo:$(this).attr("data-guardarvinculo"), cookie:$(this).attr("data-cookie"), datocookie:$(this).attr("data-datocookie")  },
            dataType: "json"
          })
          .done(function(data) {

          })
          .fail(function() {
          }); 

          $("#sidebarOptin").fadeOut();

      });        

      /** FIN PERFIL USUARIOS */



      /* MÓDULO LISTADO ACTIVIDADES */


      function filtrarActividades(){


        console.log($(".js-mod-lista-actividades-filtro-ano").val());
        console.log($(".js-mod-lista-actividades-filtro-cats").val());

        var cadenaClases="";

        if ($(".js-mod-lista-actividades-filtro-ano").val()!=""){
          cadenaClases+= " .anio_"+$(".js-mod-lista-actividades-filtro-ano").val();
        }

        if (Array.isArray($(".js-mod-lista-actividades-filtro-cats").val())){

          jQuery.each( $(".js-mod-lista-actividades-filtro-cats").val(), function( i, val ) {
            cadenaClases+= " .cat_"+val;
          });

        }

        if (cadenaClases==""){
          $(".listado-activiades .mod-actividad").show();
          $(".listado-activiades .mod-actividad").addClass('visible');
        }else{
          $(".listado-activiades .mod-actividad").each(function(index, el) {
            if ($(this).is(cadenaClases)){
              $(this).show();
              $(this).addClass('visible');
            }else{
              $(this).hide();
              $(this).removeClass('visible');
            }
          });
        }

        console.log(cadenaClases);


        $(".listado-activiades .vistaTipos ul .lista").each(function(index, el) {
          
          cadenaSelector=".listado-activiades ."+$(this).attr("data-clasi")+" .mod-actividad.visible";
          numero=$(cadenaSelector).length;
          /*
          console.log("----");
          console.log(cadenaSelector);
          console.log("----");
          console.log ($(this).attr("data-clasi")+":"+numero);
          */
          if (numero==0){
            $(this).hide();
          }else{
            $(this).show();
            $(this).find("a").trigger("click");
          }

        });


        $(".listado-activiades .vistaAnos ul .lista").each(function(index, el) {
          
          cadenaSelector=".listado-activiades ."+$(this).attr("data-clasi")+" .mod-actividad.visible";
          numero=$(cadenaSelector).length;
          /*
          console.log("----");
          console.log(cadenaSelector);
          console.log("----");
          console.log ($(this).attr("data-clasi")+":"+numero);
          */
          if (numero==0){
            $(this).hide();
          }else{
            $(this).show();
            $(this).find("a").trigger("click");
          }

        });  

        //y ahora hago los bucles otra vez solo para marcar el primero.

        $(".listado-activiades .vistaTipos ul .lista").each(function(index, el) {
          
          cadenaSelector=".listado-activiades ."+$(this).attr("data-clasi")+" .mod-actividad.visible";
          numero=$(cadenaSelector).length;

          if (numero>0){
            $(this).find("a").trigger("click");
            return false;
          }

        });


        $(".listado-activiades .vistaAnos ul .lista").each(function(index, el) {
          
          cadenaSelector=".listado-activiades ."+$(this).attr("data-clasi")+" .mod-actividad.visible";
          numero=$(cadenaSelector).length;

          if (numero>0){
            $(this).find("a").trigger("click");
            return false;
          }

        });              

      }


      $(".js-mod-lista-actividades-filtro-ano").chosen().change(function(){

        filtrarActividades();

      });

      $(".js-mod-lista-actividades-filtro-cats").chosen().change(function(){

        filtrarActividades();

      });

      $(".js-mod-lista-actividades-segmento-anio").click(function(event) {
        
         $(".listado-activiades .segmento-anio").removeClass('activo');
         $(this).addClass('activo');
         $(".listado-activiades .vistaAnos .aniovista").hide();
         $(".listado-activiades .vistaAnos .aniovista_"+$(this).attr("data-dato")).show();

      });


      $(".js-mod-lista-actividades-segmento-tipo").click(function(event) {
        
         $(".listado-activiades .segmento-tipo").removeClass('activo');
         $(this).addClass('activo');
         $(".listado-activiades .vistaTipos .tipovista").hide();
         $(".listado-activiades .vistaTipos .tipovista_"+$(this).attr("data-dato")).show();

      });  

      $(".js-mod-lista-actividades-vista-anios").click(function(event) {
        $(".listado-activiades .vistaAnos").show();
        $(".listado-activiades .vistaTipos").hide();
        $(".listado-activiades .mod-lista-actividades-vista").removeClass('activo');
        $(".listado-activiades .js-mod-lista-actividades-vista-anios").addClass('activo');
      });

      $(".js-mod-lista-actividades-vista-tipos").click(function(event) {
        $(".listado-activiades .vistaAnos").hide();
        $(".listado-activiades .vistaTipos").show();
        $(".listado-activiades .mod-lista-actividades-vista").removeClass('activo');
        $(".listado-activiades .js-mod-lista-actividades-vista-tipos").addClass('activo');        
      });          


      /* FIN MODULO LISTADO ACTIVIDADES */


      /* GALERIA */

      $(".js-verMasGaleriav2").click(function(event) {
        paginaActual=parseInt($(this).closest(".section-galeria-v2").attr("data-pagina"),10);
        totalPaginas=parseInt($(this).closest(".section-galeria-v2").attr("data-totalpaginas"),10);
        ppp=parseInt($(this).closest(".section-galeria-v2").attr("data-ppp"),10);
        paginaActual=paginaActual+1;
        $(this).closest(".section-galeria-v2").attr("data-pagina",paginaActual);
        console.log(paginaActual);        
        if (paginaActual>=totalPaginas-1){
          $(this).hide();
        }

        //muestro los elementos
        desde=(paginaActual*ppp)-1;
        for (i=desde;i<(desde+ppp);i++){
          console.log(i);
          $(".section-galeria-v2 .masonryfalso .item").eq(i).fadeIn();
        }
        //$(".section-galeria-v2 .masonryfalso .item")

      });

      $(".js-ampliacionGaleriav2").click(function(event) {

        idGal=parseInt($(this).closest('.section-galeria-v2').attr("data-idgal"),10);

        console.log(idGal);
        $(".modal-galeria-v2_"+idGal).fadeIn();
        console.log("voy a: "+(parseInt($(this).attr("data-imagen"),10)-1));
        console.log(window.swiperModalGaleria[idGal]);
        window.swiperModalGaleria[idGal].slideTo(parseInt($(this).attr("data-imagen"),10)-1);
        
      });

      $(".js-ampliacionGaleriaThumbv2").click(function(event) {

        idGal=parseInt($(this).parent().parent().parent().attr("data-idgal"),10);

        console.log(idGal);
        $(".modal-galeria-v2_"+idGal).fadeIn();
        console.log("voy a: "+(parseInt($(this).attr("data-imagen"),10)-1));
        console.log(window.swiperModalGaleria[idGal]);
        window.swiperModalGaleria[idGal].slideTo(parseInt($(this).attr("data-imagen"),10)-1);
        
      });      

      /* FIN GALERIA */


      /* CENTRO DE CONOCIMIENTO */

      $(".js-mod-centroConocimiento-filtro").chosen().change(function(){
        console.log("cambio!!");
        console.log("tengo en el dataaa:");
        console.log( $(".js-contenedorCentroConocimiento .js-paginacion").attr("data-filtros"));


        filtrosNoFormateado=$(".js-contenedorCentroConocimiento .js-paginacion").attr("data-filtros");

        if (filtrosNoFormateado!="" && filtrosNoFormateado!=undefined){
            filtros=JSON.parse(filtrosNoFormateado);
        }

        arrayPostFiltro=[];
        if (Array.isArray($(".js-mod-centroConocimiento-filtro").val())){
          jQuery.each( $(".js-mod-centroConocimiento-filtro").val(), function( i, val ) {
            arrayPostFiltro.push(parseInt(val,10));
          });
        }

        filtros.postFiltro=arrayPostFiltro;
        filtros.pagina=1;
        $(".js-contenedorCentroConocimiento .js-paginacion").attr("data-filtros",JSON.stringify(filtros));

        console.log("inserto en el data:");
        console.log( $(".js-contenedorCentroConocimiento .js-paginacion").attr("data-filtros"));

        $(".js-contenedorCentroConocimiento").trigger('refrescar');

      });


      $(".js-contenedorCentroConocimiento").on('refrescar', function(event) {

          console.log("refresco!!!!!!");
          console.log(JSON.parse(jQuery(this).find('.js-paginacion').attr("data-filtros")));

          datos=JSON.parse(jQuery(this).find('.js-paginacion').attr("data-filtros"));

          el=jQuery(this);

          el.find(".js-spinner-holder").fadeIn();

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: datos
          })
          .done(function(html) {


            el.html(html);

            el.find(".js-spinner-holder").fadeOut();

            el.find(".js-anteriorPagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                if (paginaActual>1){

                  datos.pagina=parseInt(datos.pagina,10)-1;

                }

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorCentroConocimiento").trigger("refrescar");
            PLG_scrollTo(0.5,".centro-conocimiento",0,-100);


            });

            el.find(".js-siguientePagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                if (paginaActual<totalPaginas){

                  datos.pagina=parseInt(datos.pagina,10)+1;

                }

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorCentroConocimiento").trigger("refrescar");
            PLG_scrollTo(0.5,".centro-conocimiento",0,-100);


            });

            el.find(".js-irPagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                datos.pagina=jQuery(this).attr("data-pagina");

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorCentroConocimiento").trigger("refrescar");
              PLG_scrollTo(0.5,".centro-conocimiento",0,-100);


            });


          })
          .fail(function() {
            console.log("error");
          });

      });

      $(".js-contenedorCentroConocimiento .js-anteriorPagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          if (paginaActual>1){

            datos.pagina="";
            datos.pagina=parseInt(datos.pagina,10)-1;

          }

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorCentroConocimiento").trigger("refrescar");
            PLG_scrollTo(0.5,".centro-conocimiento",0,-100);


      });

      $(".js-contenedorCentroConocimiento .js-siguientePagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          console.log(datos);

          if (paginaActual<totalPaginas){

            datos.pagina=parseInt(datos.pagina,10)+1;

          }

          console.log(datos);

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorCentroConocimiento").trigger("refrescar");
            PLG_scrollTo(0.5,".centro-conocimiento",0,-100);


      });

      $(".js-contenedorCentroConocimiento .js-irPagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          datos.pagina=jQuery(this).attr("data-pagina");

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorCentroConocimiento").trigger("refrescar");
            PLG_scrollTo(0.5,".centro-conocimiento",0,-100);


      });             

      /* FIN CENTRO DE CONOCIMIENTO */


      /* PAGINA TAGS */

      /*
      $(".js-mod-centroConocimiento-filtro").chosen().change(function(){
        console.log("cambio!!");
        console.log("tengo en el data:");
        console.log( $(".js-contenedorPaginaTags .js-paginacion").attr("data-filtros"));

        filtros=JSON.parse($(".js-contenedorPaginaTags .js-paginacion").attr("data-filtros"));

        arrayPostFiltro=[];
        if (Array.isArray($(".js-mod-centroConocimiento-filtro").val())){
          jQuery.each( $(".js-mod-centroConocimiento-filtro").val(), function( i, val ) {
            arrayPostFiltro.push(parseInt(val,10));
          });
        }
        filtros.postFiltro=arrayPostFiltro;
        filtros.pagina=1;
        $(".js-contenedorPaginaTags .js-paginacion").attr("data-filtros",JSON.stringify(filtros));

        console.log("inserto en el data:");
        console.log( $(".js-contenedorPaginaTags .js-paginacion").attr("data-filtros"));

        $(".js-contenedorPaginaTags").trigger('refrescar');

      });
      */


      $(".js-contenedorPaginaTags").on('refrescar', function(event) {

          console.log("refresco!!!!!!");
          console.log(JSON.parse(jQuery(this).find('.js-paginacion').attr("data-filtros")));

          datos=JSON.parse(jQuery(this).find('.js-paginacion').attr("data-filtros"));

          el=jQuery(this);

          el.find(".js-spinner-holder").fadeIn();

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: datos
          })
          .done(function(html) {


            el.html(html);

            el.find(".js-spinner-holder").fadeOut();

            el.find(".js-anteriorPagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                if (paginaActual>1){

                  datos.pagina=parseInt(datos.pagina,10)-1;

                }

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorPaginaTags").trigger("refrescar");


            });

            el.find(".js-siguientePagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                if (paginaActual<totalPaginas){

                  datos.pagina=parseInt(datos.pagina,10)+1;

                }

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorPaginaTags").trigger("refrescar");
            PLG_scrollTo(0.5,"body",0,-100);


            });

            el.find(".js-irPagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                datos.pagina=jQuery(this).attr("data-pagina");

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorPaginaTags").trigger("refrescar");
            PLG_scrollTo(0.5,"body",0,-100);


            });


          })
          .fail(function() {
            console.log("error");
          });

      });

      $(".js-contenedorPaginaTags .js-anteriorPagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          if (paginaActual>1){

            datos.pagina="";
            datos.pagina=parseInt(datos.pagina,10)-1;

          }

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorPaginaTags").trigger("refrescar");

            PLG_scrollTo(0.5,"body",0,-100);

      });

      $(".js-contenedorPaginaTags .js-siguientePagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          console.log(datos);

          if (paginaActual<totalPaginas){

            datos.pagina=parseInt(datos.pagina,10)+1;

          }

          console.log(datos);

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorPaginaTags").trigger("refrescar");
            PLG_scrollTo(0.5,"body",0,-100);


      });

      $(".js-contenedorPaginaTags .js-irPagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          datos.pagina=jQuery(this).attr("data-pagina");

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorPaginaTags").trigger("refrescar");
            PLG_scrollTo(0.5,"body",0,-100);


      });             

      /* FIN PAGINA TAGS */


      /** LISTADO CITAS FILTRADO */

      $(".js-contenedorCitasFiltradas").on('refrescar', function(event) {


          console.log("refresco!");
          console.log(JSON.parse(jQuery(this).find('.js-paginacion').attr("data-filtros")));

          datos=JSON.parse(jQuery(this).find('.js-paginacion').attr("data-filtros"));
          datos.action="AjaxListarCitasFiltradas";

          el=jQuery(this);

          el.find(".js-spinner-holder").fadeIn();

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: datos
          })
          .done(function(html) {

            el.html(html);

            el.find(".js-spinner-holder").fadeOut();

            el.find(".js-anteriorPagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                if (paginaActual>1){

                  datos.pagina=parseInt(datos.pagina,10)-1;

                }

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorCitasFiltradas").trigger("refrescar");

            PLG_scrollTo(0.5,"body",0,-100);

            });

            el.find(".js-siguientePagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                if (paginaActual<totalPaginas){

                  datos.pagina=parseInt(datos.pagina,10)+1;

                }

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorCitasFiltradas").trigger("refrescar");
            PLG_scrollTo(0.5,"body",0,-100);

            });

            el.find(".js-irPagina").click(function(event) {

                paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
                totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
                datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

                datos.pagina=jQuery(this).attr("data-pagina");

                jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

                jQuery(this).closest(".js-contenedorCitasFiltradas").trigger("refrescar");
            PLG_scrollTo(0.5,"body",0,-100);


            });


          })
          .fail(function() {
            console.log("error");
          });

      });

      $(".js-contenedorCitasFiltradas .js-anteriorPagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          if (paginaActual>1){

            datos.pagina="";
            datos.pagina=parseInt(datos.pagina,10)-1;

          }

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorCitasFiltradas").trigger("refrescar");
            PLG_scrollTo(0.5,"body",0,-100);


      });

      $(".js-contenedorCitasFiltradas .js-siguientePagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          console.log(datos);

          if (paginaActual<totalPaginas){

            datos.pagina=parseInt(datos.pagina,10)+1;

          }

          console.log(datos);

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorCitasFiltradas").trigger("refrescar");

            PLG_scrollTo(0.5,"body",0,-100);

      });

      $(".js-contenedorCitasFiltradas .js-irPagina").click(function(event) {

          paginaActual=parseInt(jQuery(this).closest('.js-paginacion').attr("data-pagina"),10);
          totalPaginas=parseInt(jQuery(this).closest('.js-paginacion').attr("data-total"),10);
          datos=JSON.parse(jQuery(this).closest('.js-paginacion').attr("data-filtros"));

          datos.pagina=jQuery(this).attr("data-pagina");

          jQuery(this).closest('.js-paginacion').attr("data-filtros",JSON.stringify(datos));

          jQuery(this).closest(".js-contenedorCitasFiltradas").trigger("refrescar");
            PLG_scrollTo(0.5,"body",0,-100);


      });             

      /** FIN LISTADO CITAS FILTRADO */ 


      /** LISTADO DE CÓDIGOS */

      $(".js-seleccionActividadCodigos").chosen().change(function(){

          $(".contenedorCodigos").html("Cargando...");

          $.ajax({
            method: "POST",
            url: global.ajax_url,
            data: {action:"getCodigosOrganizacion", idActividad: $(".js-seleccionActividadCodigos").val()}
          })
            .done(function( msg ) {

              $(".contenedorCodigos").html(msg);

          });        

      });

      /** FIN LISTADO DE CÓDIGOS */


      $(".js-ampliacion-imagen").click(function(event) {

        var attr = $(this).attr('src');

        if (typeof attr !== typeof undefined && attr !== false) {

          bkg=$(this).attr("src");
        }else{
          bkg=$(this).find("img").attr("src");
        }
        

        $("#ampliacionImagen100pct .contenido").css('background-image', 'url(' + bkg + ')');
        $("#ampliacionImagen100pct").fadeIn();
      });


      if ($(".cont-listado-ponentes").length > 0){

        console.log("siiiiii: "+window.location.hash.substring(0, 8));
        if(window.location.hash && window.location.hash.substring(0, 8)=="#ponente" && $(window.location.hash).length > 0) {

          $(window.location.hash).addClass("destacado");
          $('html, body').animate({
              scrollTop: $(window.location.hash).offset().top - $("header").height() -10
          }, 1000);

        }

      }


      $(".js-mostrar-info-pases").click(function(event) {
        $("#capaInfoPases").fadeIn();
      });

      $(".js-cerrar-info-pases").click(function(event) {
        $("#capaInfoPases").fadeOut();
      });      


      /** FORMULARIO REGISTRO */



      var timeoutEmailRegistroForm;
      $("#formRegistroForm #registroFormEmail").on("keyup blur",function(event) {

        clearTimeout(timeoutEmailRegistroForm);
        console.log("borro timeout");

        clearTimeout(timeoutEmailRegistroForm);
        timeoutEmailRegistroForm=setTimeout(function(){
        

          $("#capaTapadorFormPaso1").fadeIn();

          $.ajax({
            method: "POST",
            url: global.ajax_url,
            dataType: "json",
            data: { action: "existeMailUsuarioAjax", email: $("#formRegistroForm #registroFormEmail").val(), tipo:"registro" }
          })
          .done(function( msg ) {

            $("#capaTapadorFormPaso1").fadeOut();

            if (msg.error=="OK"){

              if (!$('#modalAlerta').hasClass('in')){
                $('#modalAlerta').mostrar_msg("<i class='fa fa-exclamation-triangle' aria-hidden='true'></i>", "Hemos comprobado que ya existe un usuario con este email, por favor haz login <a href='javascript:;' onclick=\"$('#modalAlerta').modal('hide'); aslan_login(false,'','')\" >aquí<a/>");
              }

            }else{

              if (msg.error=="KO" && msg.tipo=="NOPRO"){
                $('#modalAlerta').mostrar_msg(msg.titulo, msg.mensaje);
              }

            }

          });

        },1000);

      });



      $("#formRegistroForm #registroFormPais").change(function(event) {

        console.log("sii");

        if ($(this).val()=="españa"){
          $("#formRegistroForm #registroFormPrefijoTelefono").val("34");
          $("#formRegistroForm #registroFormPrefijoMovil").val("34");
          $("#formRegistroForm #registroFormPrefijoTelefono").prop('tabIndex', -1);
          $("#formRegistroForm #registroFormPrefijoMovil").prop('tabIndex', -1);      
          $("#formRegistroForm #grupoRegistroFormProvinciaCampo").hide();
          $("#formRegistroForm #registroFormProvincia").val("-");
          $("#formRegistroForm #grupoRegistroFormProvinciaSelect").show();

        }else{
          $("#formRegistroForm #registroFormPrefijoTelefono").val("");
          $("#formRegistroForm #registroFormPrefijoMovil").val("");
          $("#formRegistroForm #registroFormPrefijoTelefono").prop('tabIndex', "");
          $("#formRegistroForm #registroFormPrefijoMovil").prop('tabIndex', "");          
          $("#formRegistroForm #grupoRegistroFormProvinciaCampo").show();
          $("#formRegistroForm #registroFormProvincia").val("");
          $("#formRegistroForm #grupoRegistroFormProvinciaSelect").hide();
        }

      });


      $("#formRegistroFormPaso2 #registroFormSectorActividad").change(function(event) {
        if ($(this).val()=="T.I.C"){
          $("#formRegistroFormPaso2 .contenedorSectorTic").show();
          $("#formRegistroFormPaso2 #registroFormSectorTIC").trigger("chosen:updated");
        }else{
          $("#formRegistroFormPaso2 .contenedorSectorTic").hide();
          $("#formRegistroFormPaso2 #registroFormSectorTIC").val("");
          $("#formRegistroFormPaso2 #registroFormSectorTIC").trigger("chosen:updated");
        }
      });


      $("#formRegistroFormPaso2 #RegistroFormSectorActividad").change(function(event) {
        if ($(this).val()=="T.I.C"){
          $("#formRegistroFormPaso2 .contenedorSectorTic").show();
          $("#formRegistroFormPaso2 #RegistroFormSectorTIC").trigger("chosen:updated");
        }else{
          $("#formRegistroFormPaso2 .contenedorSectorTic").hide();
          $("#formRegistroFormPaso2 #RegistroFormSectorTIC").val("");
          $("#formRegistroFormPaso2 #RegistroFormSectorTIC").trigger("chosen:updated");
        }
      });

      /** ----------------------- */

      // control de pais para form registro , ocultando provincia select y mostrando campo abierto
      $("#formRegistroFormPaso2 #registroFormPais").change(function(event) {
        console.log(">>> cambio pais registro");
        if ($(this).val()=="españa"){
          $("#grupoRegistroFormProvinciaSelect").show();
          $("#grupoRegistroFormProvinciaCampo").hide();
        }else{
          $("#grupoRegistroFormProvinciaSelect").hide();
          $("#grupoRegistroFormProvinciaCampo").show();
        }
      })

      /** VENTANA TIPOS DE PASE */



      $(".js-mostrarModalPases").on("click",function(event) {


          $( ".contenedorCapaInfoPases" ).load( global.ajax_url, {action: "mostrarModalPasesAjax", ida:$(this).attr("data-id") }, function(){

            $("#capaInfoPasesModal").fadeIn();

            var mySwiperInfoFases = new Swiper('#swiperInfoPasesModal', {
              navigation: {
                nextEl: '.swiper-swiperInfoPasesModal-button-next',
                prevEl: '.swiper-swiperInfoPasesModal-button-prev',
              },
              slidesPerView: 3,
              observer: true,
              observeParents: true,
              initialSlide:1,
              centerInsufficientSlides: true,
              breakpoints: {
                // when window width is <= 320px
                320: {
                  slidesPerView: 1,
                },
                // when window width is <= 480px
                480: {
                  slidesPerView: 1,
                },
                // when window width is <= 640px
                960: {
                  slidesPerView: 2,
                },
                // when window width is <= 640px
                1200: {
                  slidesPerView: 3,
                },
                // when window width is <= 640px
                10000: {
                  slidesPerView: 3,
                }         
              }        
            });


          });


      });

      /** ----------------------- */


      /** OPTIN ORGANIZACIONES */
      $('.etiquetaOptin input').on('ifChanged', function(event){
        if (!$(this).is(":checked")){
          $(this).closest('.etiquetaOptin').attr("style","display:none !important;");
        }
      });    

      /** ------------------------*/


      /** MULTI LEAD */

      $(".js-logMultiOptin").click(function(event) {

          event.preventDefault();

          var orgs=JSON.parse($(this).attr("data-nombreempresa"));
          var lasorgstxt="";

          var cont=0;
          var clasemuchas="";
          if (orgs.length>3){
            clasemuchas="muchas";
          }
          $.each( orgs, function( key, value ) {

              lasorgstxt+="<span class='unaorg "+clasemuchas+" '>"+value+"</span>";
          });


          $(".js-logMultiOptinAceptada").attr("data-idu", $(this).attr("data-idu"));
          $(".js-logMultiOptinAceptada").attr("data-ido", $(this).attr("data-ido") );
          $(".js-logMultiOptinAceptada").attr("data-tipo", $(this).attr("data-tipo"));
          $(".js-logMultiOptinAceptada").attr("data-idr", $(this).attr("data-idr"));
          $(".js-logMultiOptinAceptada").attr("data-url", $(this).attr("href")); 

          if ($(this).attr("data-logoempresa")){
            $("#modalMultiOptinEmpresa .modal-header").show();
            $("#modalMultiOptinEmpresa .modal-header .modal-title").html("<img src='"+$(this).attr("data-logoempresa")+"' class='img-fluid'>");
          }else{

          }

          $("#modalMultiOptinEmpresa .js-nombreOptinEmpresa").html(lasorgstxt);

          $("#modalMultiOptinEmpresa").modal({backdrop: 'static', keyboard: false});   

      });

      $(".js-logMultiOptinAceptada").click(function(event) {
        /* Act on the event */

          event.preventDefault();

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "logMultiOptin", idu: $(this).attr("data-idu"), ido: $(this).attr("data-ido"), tipo:  $(this).attr("data-tipo"), idr:  $(this).attr("data-idr"), guardarvinculo:"true" },
            dataType: "json"
          })
          .done(function(data) {

          })
          .fail(function() {
          }); 

          $("#modalMultiOptinEmpresa").modal("hide");

          if ($(this).attr("data-url")!="" && $(this).attr("data-url")!="javascript:;"){
            window.open($(this).attr("data-url"));
          }

      });

      $(".js-logMultiOptinAceptadaCerrar").click(function(event) {
        /* Act on the event */

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "logMultiOptin", idu: $(this).closest('#modalMultiOptinEmpresa').find(".js-logMultiOptinAceptada").attr("data-idu"), ido: $(this).closest('#modalMultiOptinEmpresa').find(".js-logMultiOptinAceptada").attr("data-ido"), tipo:  $(this).closest('#modalMultiOptinEmpresa').find(".js-logMultiOptinAceptada").attr("data-tipo"), idr:  $(this).closest('#modalMultiOptinEmpresa').find(".js-logMultiOptinAceptada").attr("data-idr"), guardarvinculo:"false" },
            dataType: "json"
          })
          .done(function(data) {

          })
          .fail(function() {
          }); 

          window.open($(this).closest('#modalMultiOptinEmpresa').find(".js-logMultiOptinAceptada").attr("data-url"));

      });

      /** ------------------------*/

      /** MENSAJERIA */

      $(".js-mensajeria").click(function(event) {

          event.preventDefault();

          $("#modalMensajeria .contFoto .foto").css("background-image", 'url(' + $(this).attr("data-avatar") + ')');
          $("#modalMensajeria .contFoto .foto img").attr("src", $(this).attr("data-avatar"));
          $("#modalMensajeria .js-nombreDestinatario").html($(this).attr("data-nombre"));
          $("#modalMensajeria #idto").val($(this).attr("data-destino"));

          if ($(this).attr("data-tipo")){
            $("#modalMensajeria #mensajeTipo").val($(this).attr("data-tipo"));
            $('#modalMensajeria #mensajeTipo').trigger("chosen:updated");
          }

          $("#modalMensajeria").modal("show");   

      });

      $(".js-borrarMensaje").click(function(event) {

          var obj=$(this);

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "ajax_aslan_deletemensajes", id: $(this).attr('data-id'), clave: $(this).attr('data-clave') },
            dataType: "json"
          })
          .done(function(data) {

            if (data.error=="OK"){
              obj.closest('.contenedorMensaje').fadeOut();
               obj.closest('.contenedorMensaje').remove();
            }

          })
          .fail(function() {
          });  

      });    

      $(".js-seleccionMensajes").change(function(event) {

        if ($(this).val()==""){
          console.log("muestro todo: .js-tipomensaje");
          $(".js-tipomensaje").show();
        }else{
          console.log("Oculto todo: .js-tipomensaje");
          $(".js-tipomensaje").hide();
          console.log("Muestro todo: .js-tipomensaje-"+$(this).val());
          $(".js-tipomensaje-"+$(this).val()).show();
        }

      });

      $(".js-configurarNotificacionesMensajes").click(function(event) {

          var obj=$(this);

          setTimeout(function(){

            console.log("cambio");
            console.log(obj.find("input").is(":checked"));

            $.ajax({
              url: global.ajax_url,
              type: 'POST',
              data: { action: "ajax_aslan_confNotificacionMensaje", estado:obj.find("input").is(":checked") },
              dataType: "json"
            })
            .done(function(data) {

            })
            .fail(function() {
            });            

          },200);


      });      

      /** ------------------------*/

      /** FAST NETWORKING */

      //console.log("swiperFastNetwork")

      swiperFastNetwork = new Swiper('#swiperFastNetwork', {
          slidesPerView: 1,
          simulateTouch: false,
          allowTouchMove: false,
          spaceBetween: 100,
          autoHeight:false,
          // navigation: {
          //   nextEl: '#swiper-registroForm .swiper-button-next',
          //   prevEl: '#swiper-registroForm .swiper-button-prev',
          // },
      });    


      $(".js-solicitar-invitacion").click(function(event) {
      
          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "solicitarInvitacionFastNetwork", idf: $(this).attr('data-id'), clave: $(this).attr('data-clave') },
            dataType: "json"
          })
          .done(function(data) {

            if (data.resultado=="OK"){

              $("#modalAlerta .modal-title").html(data.titulo);
              $("#modalAlerta .modal-body").html(data.texto);
              $("#modalAlerta").modal();    

            }else{

              $("#modalAlerta .modal-title").html(data.titulo);
              $("#modalAlerta .modal-body").html(data.texto);
              $("#modalAlerta").modal();

            }
            

          })
          .fail(function() {
          });

      });


      /** ------------------------*/  


      /** BOTON NEWSLETTER */

      $(".js-suscripcion-newsletter").click(function(event) {
      
          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "suscripcionNewsUsuario", idc: $(this).attr('data-idc'), clave: $(this).attr('data-clave') },
            dataType: "json"
          })
          .done(function(data) {

            if (data.resultado=="OK"){

              $("#modalAlerta .modal-title").html(data.titulo);
              $("#modalAlerta .modal-body").html(data.texto);
              $("#modalAlerta").modal();    

              jQuery(".bannerNewsletter").hide();
            }else{
              $("#modalAlerta .modal-title").html(data.titulo);
              $("#modalAlerta .modal-body").html(data.texto);
              $("#modalAlerta").modal();    
            }
            

          })
          .fail(function() {
          });

      });

      /** ------------------------*/  


      /** ÚLTIMA HORA */

      if ($("#ultima-hora").length>0){


        function initUltimaHora(){

          jQuery.ajax({
            method: "POST",
            url: global.ajax_url,
            data: { action: "getUltimaHoraAjax"},
            dataType: "html",
          })
          .done(function( msg ) {

            if (msg!=""){

              jQuery("#ultima-hora").fadeIn();
              $(".contenedorUltimaHora").html(msg);
              swiperUltimaHora.update();  

            }else{
              jQuery("#ultima-hora").fadeOut();
            }


          });          

        }


        var swiperUltimaHora = new Swiper(".swiper-salas", {
          pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });


        setInterval(function(){

          initUltimaHora();

        },300000000); 

        initUltimaHora();


        jQuery(".js-togglePanelUltimaHora").click(function(event) {
          if (jQuery("#ultima-hora").hasClass('cerrado')){
            jQuery("#ultima-hora").removeClass('cerrado');
            jQuery('#ultimahoracollapse').slideDown();

            jQuery.ajax({
              method: "POST",
              url: global.ajax_url,
              data: { action: "estadoUltimaHoraAjax", cerrado:false},
              dataType: "html",
            })
            .done(function( msg ) {

            }); 

          }else{
            jQuery('#ultimahoracollapse').slideToggle();
          }
        });


        jQuery(".js-cerrarUltimaHora").click(function(event) {
          jQuery("#ultima-hora").addClass('cerrado');

          jQuery.ajax({
            method: "POST",
            url: global.ajax_url,
            data: { action: "estadoUltimaHoraAjax", cerrado:true},
            dataType: "html",
          })
          .done(function( msg ) {

          }); 

        });

      }

      /** ------------------------*/    


      /** SHORTCODE DE GUARDAR INTERES INSCRIPCION */

      jQuery(".js-interesinscripcion").click(function(event) {        

          $.ajax({
            url: global.ajax_url,
            type: 'POST',
            data: { action: "guardarinteresinscripcion", idt: $(this).attr('data-idt'), idi: $(this).attr('data-idi') },
            dataType: "json"
          })
          .done(function(data) {

            if (data.resultado=="OK"){

              $("#modalAlerta .modal-title").html(data.titulo);
              $("#modalAlerta .modal-body").html(data.texto);
              $("#modalAlerta").modal();    

            }else{
              $("#modalAlerta .modal-title").html(data.titulo);
              $("#modalAlerta .modal-body").html(data.texto);
              $("#modalAlerta").modal();    
            }
            

          })
          .fail(function() {
          });

      });

      /** ------------------------*/

      /** PARTE DE MODAL INTRUSIVO. */
      $.ajax({
        method: "POST",
        url: global.ajax_url,
        data: {action:"getModalIntrusivo",idp:$("#modalIntrusivo").attr("data-idpag")},
        dataType: "json",
      })
        .done(function( msg ) {

          if (msg.resultado=="OK"){
            $("#modalIntrusivo .vinculoimagen").attr("href",msg.url);
            $("#modalIntrusivo .vinculoimagen img").attr("src",msg.imagen);
            $("#modalIntrusivo").fadeIn();
          }

      });

      $("#modalIntrusivo .back").click(function() {
        $("#modalIntrusivo").fadeOut();
      });

      $("#modalIntrusivo .cerrar").click(function() {
        $("#modalIntrusivo").fadeOut();
      });      

      /** ------------------------*/


      /** PARTE DE INSCRIPCIÓN RÁPIDA CON QR */

      var validandoEmailInscripcionRapidaQR=false;
      var enviadoEmailInscripcionRapidaQR=false;
      function validarEmailInscripcionRapidaQR(){

        if ($("#formInscripcion #inscripcionRapidaEmail").val()!="" && $("#formInscripcion #inscripcionRapidaEmail")[0].checkValidity() && $("#formInscripcion #inscripcionRapidaQRoptin").is(":checked") && !enviadoEmailInscripcionRapidaQR ){

          if (validandoEmailInscripcionRapidaQR==false){

            console.log("valido email inscripcion rapida QR");

            validandoEmailInscripcionRapidaQR=true;

            $("#capaTapadorFormPaso1").fadeIn();

            $.ajax({
              method: "POST",
              url: global.ajax_url,
              dataType: "json",
              data: { action: "existeMailUsuarioAjax", email: $("#formInscripcion #inscripcionRapidaEmail").val() }
            })
            .done(function( msg ) {


              if (msg.error=="OK"){

                //guardo el email en el campo original para que todo siga funcionando correctamente.
                $("#formInscripcion #inscripcionEmail").val($("#formInscripcion #inscripcionRapidaEmail").val());
                
                //guardo un valor en el botón de envío 
                
                
                objetoClic=$("#modalClaveInscripcion .js-claveRellenadoInscripcion");

                $.ajax({
                  method: "POST",
                  url: global.ajax_url,
                  dataType: "json",
                  data: { action: "enviaMailClave", email: $("#formInscripcion #inscripcionRapidaEmail").val(), idpi: objetoClic.attr("data-idp"), rapidaQR: "1" }
                })
                .done(function( msg ) {

                  $("#capaTapadorFormPaso1").fadeOut();                  
      
                  if (msg.error=="OK"){
      
                    console.log("Enviado!!");

                    enviadoEmailInscripcionRapidaQR=true;

                    validandoEmailInscripcionRapidaQR=false;

                    $("#formInscripcion .miniFormRapidoFormulario").hide();
                    $("#formInscripcion .miniRespuestaRapidoFormulario .emailRapido").html($("#formInscripcion #inscripcionRapidaEmail").val());
                    $("#formInscripcion .miniRespuestaRapidoFormulario").show();


                  }

                  if (msg.error=="KO_EXISTE"){
                    jQuery("#modalLogin .loginRedirect-url").attr("value",'/mis-inscripciones/');
                    jQuery('#modalAlerta').mostrar_msg("<i class='fa fa-exclamation-triangle' aria-hidden='true'></i>", "Hemos comprobado que ya existe una inscripción para este correo electrónico. <a href='javascript:;' onclick=\"$('#modalAlerta').modal('hide'); aslan_login(false,'','')\" >Acceda a su área privada > Inscripciones<a/>");
                  }                  

                });

              }else{

                $("#capaTapadorFormPaso1").fadeOut();

                $("#formInscripcion .camposRestoFormulario").removeClass("d-none");
                $("#formInscripcion .camposRapidoFormulario").addClass("d-none");
                jQuery("#swiper-inscripcion")[0].swiper.update()

                validandoEmailInscripcionRapidaQR=false;

              }

            });
              
          }

        }else{
          if (!$("#formInscripcion #inscripcionRapidaEmail")[0].checkValidity()){
            $("#formInscripcion #inscripcionRapidaEmail").closest(".form-group").find(".form-control-feedback").removeClass("glyphicon-ok").addClass("glyphicon-remove");
            $("#formInscripcion #inscripcionRapidaEmail").closest(".form-group").addClass("has-error").removeClass("has-success");
          }else{
            $("#formInscripcion #inscripcionRapidaEmail").closest(".form-group").find(".form-control-feedback").removeClass("glyphicon-remove");
            $("#formInscripcion #inscripcionRapidaEmail").closest(".form-group").removeClass("has-error");
          }
          if (!$("#formInscripcion #inscripcionRapidaQRoptin").is(":checked")){
            $("#formInscripcion #inscripcionRapidaQRoptin").closest(".form-check").find(".js-mostrarModalCheck").attr("style","color:red;");
          }else{
            $("#formInscripcion #inscripcionRapidaQRoptin").closest(".form-check").find(".js-mostrarModalCheck").attr("style","");
          }        
        }

      }

      // $("#formInscripcion #inscripcionRapidaEmail").on("keyup blur",function(event) {

      //   validarEmailInscripcionRapidaQR();

      // });

      // $("#formInscripcion #inscripcionRapidaQRoptin").on("change",function(event) {

      //   console.log("change optin");
      //   validarEmailInscripcionRapidaQR();

      // });    
      
      $("#formInscripcion .js-comprobarInscripcionRapidaQR").click(function(event) { 
        
        validarEmailInscripcionRapidaQR();

      });

      $("#formInscripcion .js-corregirDatosInscripcion").click(function(event) { 
        
        $(".rapidoQR").removeClass("rapidoQR");
        $("#formInscripcion .detalleDatos").hide();
        $("#formInscripcion .corrigeDatos").show();
        $('html, body').animate({
          scrollTop: $("#formInscripcion").offset().top - $("header").height() -10
        }, 1000);        
        

      });      

      

      

      /** ------------------------*/


      // ***************************************
      // NUEVA PÁGINA DE TAGS
      // ***************************************


      var swiper = new Swiper(".swiper-tags-noticias", {
        slidesPerView: 3.5,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-tags-noticias-next",
          prevEl: ".swiper-tags-noticias-prev",
        },
        pagination: {
          el: ".swiper-tags-noticias-pag",
          clickable: true,
        },
        breakpoints: {
          // when window width is <= 320px
          320: {
            slidesPerView: 1,
          },
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
          },
          // when window width is <= 640px
          960: {
            slidesPerView: 2,
          },
          // when window width is <= 640px
          1200: {
            slidesPerView: 3.5,
          },
          // when window width is <= 640px
          10000: {
            slidesPerView: 3.5,
          }         
        }   
      });

      // ***************************************
      // ***************************************
      // ***************************************




    }
  };
 // Attach function to window
  window.LISTENERS = LISTENERS;
})(jQuery);